/**
 * Module dependencies
 */
const React = require('react');
const PropTypes = require('prop-types');
const Script = require('nordic/script');

/**
 * Gtm Component
 */
function Gtm({ lowEnd, id }) {
  /* Key for analytics */
  return (
    <div>
      {!lowEnd ? (
        <Script on="now">
          {`
        (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','${id}');
      `}
        </Script>
      ) : (
        <iframe
          title="Google Tag Manager"
          src={`https://www.googletagmanager.com/ns.html?id=${id}`}
          height="0"
          width="0"
          style={{ display: 'none', visibility: 'hidden' }}
        />
      )}
    </div>
  );
}

Gtm.propTypes = {
  lowEnd: PropTypes.bool.isRequired,
  id: PropTypes.string.isRequired,
};

module.exports = Gtm;
