/* eslint-disable camelcase */
import { newrelic_browser } from '../../config/default';

const { ignore_messages } = newrelic_browser;

const shouldIgnoreError = (error) => ignore_messages.includes(error.message);

const excludeErrorsNewrelic = () => {
  if (window && window.newrelic) {
    console.log('newrelic');
    window.newrelic.setErrorHandler(error => {
      if (shouldIgnoreError(error)) {
        return true;
      }
      return false;
    });
  }
};

export default excludeErrorsNewrelic;
