const executeNative = (method, args) => {
  window.MobileWebKit.executeNative({ method, args });
};

const openInternalDeeplink = (deeplink) => {
  if (window && window.MobileWebKit) {
    if (window.MobileWebKit.engine === window.MobileWebKit.Engines.V200) {
      window.MobileWebKit.navigation.push(deeplink);
    } else {
      executeNative('open_internal_deeplink', { deeplink, replace: true });
    }
  }
};

export { openInternalDeeplink };
