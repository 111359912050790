const WEBVIEW_URL = 'meli://webview/?url=';
const FALLBACK_WAITING_TIME_MS = 3_000;
const FALLBACK_WAITING_TIME_WITH_MARGIN_OF_ERROR_MS = FALLBACK_WAITING_TIME_MS * 1.1;

class Deeplink {
  constructor(config) {
    this.config = config;
  }

  static buildDeepLinkUrl({ url, params }) {
    // available queryparams for MELI Webviews can be found on
    // https://sites.google.com/mercadolibre.com/mobile/arquitectura/libs-utilitarias/webkit/webview-landing/queryparams
    const deeplinkQueryParams = Object.keys(params).map(key => `${key}=${params[key]}`);

    return `${WEBVIEW_URL + encodeURIComponent(url)}&${deeplinkQueryParams.join('&')}`;
  }

  getVisibilityEventType() {
    let visibilityChange;

    if (typeof document.hidden !== 'undefined') {
      visibilityChange = 'visibilitychange';
    } else if (typeof document.msHidden !== 'undefined') {
      visibilityChange = 'msvisibilitychange';
    } else if (typeof document.webkitHidden !== 'undefined') {
      visibilityChange = 'webkitvisibilitychange';
    }

    return visibilityChange;
  }

  navigateToWebview() {
    const { deeplink } = this.config;
    const deepLinkUrl = Deeplink.buildDeepLinkUrl(deeplink);

    window.location = deepLinkUrl;
  }

  handleFallback() {
    const visibilityEventType = this.getVisibilityEventType();
    const { goesToWebview, staysOnBrowser } = this.config.callbacks;
    let fallbackTimeout;

    const inferWebviewIsOpen = () => {
      clearTimeout(fallbackTimeout);
      goesToWebview();
    };

    if (typeof document.addEventListener !== 'undefined' && visibilityEventType) {
      // we use the Visibility API to check if the page is hidden to infer that the user has the app installed and
      // has successfully opened the activation flow in there.
      // https://developer.mozilla.org/en-US/docs/Web/API/Page_Visibility_API
      document.addEventListener(visibilityEventType, inferWebviewIsOpen);
    }

    // as a last resort we expect the timeout to execute with some delay if the user went to the MELI App
    // this final check is done if the blur or visibility event is not triggered for some reason.
    const timestampBeforeFallback = Date.now();
    fallbackTimeout = setTimeout(() => {
      const elapsedTimeSinceTimeoutExecution = Date.now() - timestampBeforeFallback;
      if (elapsedTimeSinceTimeoutExecution > FALLBACK_WAITING_TIME_WITH_MARGIN_OF_ERROR_MS) {
        // if the user is not in the browser, the current browser tab will be on background and
        // will have low priority affecting the timer execution exceeding the margin of error for the fallback
        // we can assume that the user went to the the app and execute the webview callback
        goesToWebview();
      } else {
        // if the user doesn't have the app installed nothing will happen until the timer runs out and then
        // the buying flow will continue in the same browser tab.
        staysOnBrowser();
      }
    }, FALLBACK_WAITING_TIME_MS);
  }

  openInMeliApp() {
    this.handleFallback();
    this.navigateToWebview();
  }
}

export default Deeplink;
